// import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import AddIncome from './pages/AddIncome';
import Expense from './pages/Expense';
import Home from './pages/Home';
import Income from './pages/Income';
// import './App.css';

function App() {
  return (
    <>
      <div className="min-h-screen max-w-md my-0 mx-auto bg-background relative overflow-auto shadow-2xl p-4">
        <h1 className="text-center">Hello World!</h1>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/income' element={<Income />} />
          <Route path='/income/add' element={<AddIncome />} />
          <Route path='/expense' element={<Expense />} />
        </Routes>
      </div>
      <div className="w-full fixed bottom-0">
        <Navbar />
      </div>
    </>
  );
}

export default App;
