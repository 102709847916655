
export default function AddIncome() {

    return (
        <div>


            <select className="block w-full text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 mb-4" name="animals">
                <option value="">
                    Select an option
                </option>
                <option value="dog">
                    Dog
                </option>
                <option value="cat">
                    Cat
                </option>
                <option value="hamster">
                    Hamster
                </option>
                <option value="parrot">
                    Parrot
                </option>
                <option value="spider">
                    Spider
                </option>
                <option value="goldfish">
                    Goldfish
                </option>
            </select>


            <div className="flex relative mb-4">
                <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                    Rp
                </span>
                <input type="text" id="with-email" className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none" name="nominal" />
            </div>


            <label className="text-gray-700" htmlFor="name">
                <textarea className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent" id="comment" placeholder="Enter your comment" name="comment" rows="5" cols="40">
                </textarea>
            </label>


            <button type="button" className="py-2 px-4 mt-4 bg-primary text-white w-full text-center text-base font-semibold shadow-md rounded-lg ">
                Valider
            </button>


        </div>
    )
}