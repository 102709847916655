import { Popover, Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom'

export default function Navbar() {
    const location = useLocation()
    const { pathname } = location

    return (
        <div className="mx-auto block max-w-md p-4">
            <div className="bg-white p-2 rounded-xl ">
                <div className="grid grid-cols-5 relative p-1">
                    <Link to='/' className='flex justify-center'>
                        <svg width="23" height="34" viewBox="0 0 81 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={pathname === '/' ? 'fill-primary'  : ''} fillRule="evenodd" clipRule="evenodd" d="M2.10871 29.8036C0.541992 33.21 0.541992 37.0852 0.541992 44.8358V69.0413C0.541992 79.8051 0.541992 85.1869 3.88586 88.5308C6.9201 91.565 11.6324 91.846 20.5211 91.872V63.3333C20.5211 57.0281 25.6325 51.9167 31.9378 51.9167H49.0628C55.368 51.9167 60.4795 57.0281 60.4795 63.3333V91.872C69.3682 91.846 74.0805 91.565 77.1148 88.5308C80.4586 85.1869 80.4587 79.8051 80.4587 69.0413V44.8358C80.4587 37.0852 80.4586 33.21 78.8919 29.8036C77.3252 26.3972 74.3829 23.8752 68.4983 18.8312L62.7899 13.9384C52.1535 4.82141 46.8353 0.262939 40.5003 0.262939C34.1654 0.262939 28.8472 4.82141 18.2107 13.9384L12.5024 18.8312C6.61775 23.8752 3.67543 26.3972 2.10871 29.8036ZM49.0628 91.8747L49.0628 63.3333H31.9378V91.8747H49.0628Z" fill="#8D96AC" />
                        </svg>
                    </Link>
                    <button className='flex justify-center'>
                        <svg width="33" height="33" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M26.7544 82.5934C28.4604 85.1994 30.4708 87.6119 32.7541 89.7761C38.4119 95.1385 45.5023 98.7466 53.1676 100.164C60.833 101.581 68.7447 100.746 75.9457 97.7612C83.1467 94.776 89.3284 89.768 93.7428 83.3432C98.1572 76.9183 100.615 69.3519 100.82 61.5594C101.024 53.7668 98.9655 46.082 94.8938 39.4347C90.822 32.7875 84.9112 27.4625 77.8765 24.1042C73.1156 21.8314 67.9781 20.5252 62.7585 20.2324L65.5575 30.6783C68.3058 31.1443 70.9907 31.9904 73.5324 33.2038C78.8084 35.7225 83.2415 39.7162 86.2953 44.7017C89.3492 49.6871 90.8929 55.4507 90.7397 61.2952C90.5865 67.1396 88.7429 72.8144 85.4321 77.633C82.1213 82.4516 77.4851 86.2076 72.0843 88.4465C66.6836 90.6854 60.7497 91.3113 55.0007 90.2484C49.2517 89.1856 43.9339 86.4795 39.6906 82.4577C38.8046 81.6179 37.9734 80.7283 37.2002 79.7945L26.7544 82.5934Z" fill="#8D96AC" />
                            <path d="M29.8084 20.5017C33.7251 17.4964 38.0545 15.0882 42.6548 13.3472C44.769 12.547 45.8262 12.1469 46.8218 12.6572C47.8175 13.1674 48.1304 14.3353 48.7563 16.671L59.1953 55.63C59.8104 57.9257 60.118 59.0736 59.5959 59.9778C59.0738 60.8821 57.926 61.1897 55.6303 61.8048L16.6713 72.2438C14.3356 72.8697 13.1677 73.1826 12.228 72.5755C11.2882 71.9684 11.1062 70.8528 10.742 68.6217C9.94961 63.7672 9.87044 58.8138 10.5148 53.9192C11.379 47.3551 13.5276 41.0254 16.838 35.2916C20.1485 29.5578 24.5558 24.5322 29.8084 20.5017Z" stroke="#8D96AC" strokeWidth="10.0833" />
                        </svg>
                    </button>
                    {/* <div className="flex justify-center"> */}
                    <Popover className="flex justify-center">
                        {({ open }) => (
                            <>
                                <Popover.Button className={`bg-primary p-3 rounded-full shadow-xl absolute bottom-1 transition duration-200 ${open ? 'transform rotate-45' : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" style={{ fill: "rgba(255, 255, 255, 1)" }}><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>
                                </Popover.Button>

                                <Transition className="absolute z-10 text-white bottom-20 bg-primary rounded-full shadow-2xl"
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Popover.Panel>
                                        <div className="grid grid-cols-2">
                                            <div className="p-3 pl-4 flex">
                                                <svg width="24" height="24" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M24.8026 45.7401L18.8441 40.9734C17.9731 43.4873 17.5 46.187 17.5 48.9972C17.5 62.5282 28.469 73.4972 42 73.4972C55.531 73.4972 66.5 62.5282 66.5 48.9972C66.5 46.187 66.0269 43.4873 65.1559 40.9734L59.1975 45.7401C59.3961 46.7955 59.5 47.8842 59.5 48.9972C59.5 58.6622 51.665 66.4972 42 66.4972C32.335 66.4972 24.5 58.6622 24.5 48.9972C24.5 47.8842 24.6039 46.7955 24.8026 45.7401Z" fill="white" />
                                                    <path d="M42 45.499L39.8136 48.2321L42 49.9812L44.1864 48.2321L42 45.499ZM45.5 13.999C45.5 12.066 43.933 10.499 42 10.499C40.067 10.499 38.5 12.066 38.5 13.999L45.5 13.999ZM22.3136 34.2321L39.8136 48.2321L44.1864 42.766L26.6864 28.766L22.3136 34.2321ZM44.1864 48.2321L61.6864 34.2321L57.3136 28.766L39.8136 42.766L44.1864 48.2321ZM45.5 45.499L45.5 13.999L38.5 13.999L38.5 45.499L45.5 45.499Z" fill="white" />
                                                </svg>
                                                <Popover.Button as={Link} to="/income/add">
                                                    Pemasukan
                                                </Popover.Button>
                                                {/* <Link to="/income">Pemasukan</Link> */}
                                            </div>
                                            <div className="p-3 pr-4 flex">
                                                <svg width="24" height="24" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M24.8766 45.7992L18.7754 40.9182C17.8953 43.4486 17.417 46.167 17.417 48.9972C17.417 62.5742 28.4233 73.5806 42.0003 73.5806C55.5773 73.5806 66.5837 62.5742 66.5837 48.9972C66.5837 46.167 66.1054 43.4484 65.2252 40.918L59.124 45.799C59.3164 46.8357 59.417 47.9047 59.417 48.9972C59.417 58.6162 51.6193 66.4139 42.0003 66.4139C32.3814 66.4139 24.5837 58.6162 24.5837 48.9972C24.5837 47.9048 24.6842 46.8359 24.8766 45.7992Z" fill="white" />
                                                    <path d="M42 13.999L39.7615 11.2009L42 9.41012L44.2385 11.2009L42 13.999ZM45.5833 45.499C45.5833 47.478 43.979 49.0824 42 49.0824C40.021 49.0824 38.4167 47.478 38.4167 45.499L45.5833 45.499ZM22.2615 25.2009L39.7615 11.2009L44.2385 16.7971L26.7385 30.7971L22.2615 25.2009ZM44.2385 11.2009L61.7385 25.2009L57.2615 30.7971L39.7615 16.7971L44.2385 11.2009ZM45.5833 13.999L45.5833 45.499L38.4167 45.499L38.4167 13.999L45.5833 13.999Z" fill="white" />
                                                </svg>
                                                <Popover.Button as={Link} to="/expense">
                                                    Pengeluaran
                                                </Popover.Button>
                                                {/* <Link to="/expense">Pengeluaran</Link> */}
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                    {/* <button className="bg-primary p-3 rounded-full shadow-xl absolute bottom-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" style={{ fill: "rgba(255, 255, 255, 1)" }}><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>
                        </button> */}
                    {/* </div> */}
                    <button className='flex justify-center'>
                        <svg width="30" height="30" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="13.625" y="27.25" width="81.75" height="68.125" rx="9.08333" stroke="#8D96AC" strokeWidth="9.08333" />
                            <path d="M13.625 45.4167C13.625 36.8528 13.625 32.5709 16.2854 29.9104C18.9459 27.25 23.2278 27.25 31.7917 27.25H77.2083C85.7722 27.25 90.0541 27.25 92.7146 29.9104C95.375 32.5709 95.375 36.8528 95.375 45.4167H13.625Z" fill="#8D96AC" />
                            <path d="M31.7915 13.625L31.7915 27.25" stroke="#8D96AC" strokeWidth="9.08333" strokeLinecap="round" />
                            <path d="M77.2085 13.625L77.2085 27.25" stroke="#8D96AC" strokeWidth="9.08333" strokeLinecap="round" />
                            <rect x="31.7915" y="54.5" width="18.1667" height="9.08333" rx="2.27083" fill="#8D96AC" />
                            <rect x="31.7915" y="72.6667" width="18.1667" height="9.08333" rx="2.27083" fill="#8D96AC" />
                            <rect x="59.0415" y="54.5" width="18.1667" height="9.08333" rx="2.27083" fill="#8D96AC" />
                            <rect x="59.0415" y="72.6667" width="18.1667" height="9.08333" rx="2.27083" fill="#8D96AC" />
                        </svg>
                    </button>
                    <button className='flex justify-center'>
                        <svg width="30" height="30" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1665 31.7917C18.1665 23.2278 18.1665 18.9459 20.827 16.2854C23.4874 13.625 27.7693 13.625 36.3332 13.625H72.6665C81.2304 13.625 85.5123 13.625 88.1727 16.2854C90.8332 18.9459 90.8332 23.2278 90.8332 31.7917V68.125C90.8332 80.9708 90.8332 87.3937 86.8425 91.3843C82.8518 95.375 76.4289 95.375 63.5832 95.375H45.4165C32.5707 95.375 26.1478 95.375 22.1572 91.3843C18.1665 87.3937 18.1665 80.9708 18.1665 68.125V31.7917Z" stroke="#8D96AC" strokeWidth="9.08333" />
                            <path d="M68.125 81.75L68.125 95.375M40.875 81.75L40.875 95.375" stroke="#8D96AC" strokeWidth="9.08333" strokeLinecap="round" />
                            <path d="M40.875 36.3333L68.125 36.3332" stroke="#8D96AC" strokeWidth="9.08333" strokeLinecap="round" />
                            <path d="M40.875 54.5L68.125 54.5" stroke="#8D96AC" strokeWidth="9.08333" strokeLinecap="round" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}