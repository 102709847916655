import { Link } from "react-router-dom";

export default function Home() {

    return (
        <div className="p-4">
            <h1 className="text-center">Home</h1>

            <div className="grid grid-cols-2 my-5">
                <div className="p-4">
                    <Link to='/income' className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">Pemasukan</Link>
                </div>
                <div className="p-4">
                    <Link to='/expense' className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">Pengeluaran</Link>
                </div>
            </div>
        </div>
    )
}